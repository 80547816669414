/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'

import {
  Page,
  Flex,
  H1,
  Text,
  Box,
  Hr,
  FeatherIcon,
  Badge,
  Truncate,
  Link,
} from '../elements'
import {
  BoardgameSummary,
  // TournamentPrizes,
  Description,
  // More,
  ExhibitorEntry,
  SEO,
  HeadingComplex,
  AnimationCategories,
  PlaceIcon,
  BoardgamePictos,
  SectionAnimations,
  SectionGuests,
  SectionExhibitors,
  Youtube,
  FacebookCircle,
} from '../components'

const BoardgameTemplate = ({ data: { boardgame } }) => {
  // eslint-disable-next-line no-console
  // console.log(boardgame)

  const {
    relativePath,
    nameCanonical,
    name,
    nameFallback,
    audience,
    age,
    minPlayers,
    maxPlayers,
    duration,
    website,
    facebook,

    description,
    pitch,
    learningAttribute,
    accessibility = [],
    featuredImage,
    youtubeVideo: { id: videoId },
    authors,
    illustrators,
    // publisher,
    exhibitors = [],
    animations,
    inventedBy,
    illustratedBy,
  } = boardgame

  const color = 'boardgame'
  const mainColor = 'boardgame'

  return (
    <Page>
      {({ t, currentLocale }) => (
        <React.Fragment>
          <SEO
            {...{
              url: `${relativePath}`,
              title: nameFallback[currentLocale],
              // description: true,
            }}
          />
          <HeadingComplex
            {...{
              type: 'boardgame',
              // icon: category,
              // iconColor: `flag_${category}`,
              nameCanonical,
              name: nameFallback,
              // place,
              image: featuredImage,
            }}
          />
          <Flex.container>
            <AnimationCategories
              {...{
                categories: [audience],
                color: 'textAlt',
                bg: mainColor,
                fontSize: 1,
                mt: 2,
                mb: 1,
                // container: true,
              }}
            />
            {/* <PlaceIcon
              {...{
                icon: 'animation',
                place,
                tPlace: t(place),
              }}
            /> */}
          </Flex.container>
          <Flex.container
            {...{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Truncate {...{}}>
              {website && (
                <Link
                  {...{
                    href: website,
                    target: '_blank',
                    rel: 'noopener noreferrer',

                    color: mainColor,
                    fontSize: [1, 1, 2],
                  }}
                >
                  {website}
                </Link>
              )}
            </Truncate>
            <FacebookCircle
              {...{
                color: 'textAlt',
                backgroundColor: mainColor,
                link: facebook,
              }}
            />
          </Flex.container>

          <BoardgamePictos
            {...{
              age,
              minPlayers,
              maxPlayers,
              duration,
              color,
              size: 3,
              container: true,
            }}
          />
          {/* <BoardgameSummary {...{ boardgame, color }} /> */}

          {!!(accessibility && accessibility.length) && (
            <Flex.container
              {...{
                alignItems: 'center',
              }}
            >
              <Text {...{ color: 'text', fontSize: 1, m: 0, mr: 2 }}>
                {`${t('accessibility')} :`}
              </Text>
              {accessibility.map(
                a =>
                  a &&
                  a !== 'differentLanguage' && (
                    <FeatherIcon
                      {...{
                        key: a,
                        icon: `bgf_${a}`,
                        // color: 'white',
                        // fill: color,
                        size: 5,
                        mr: 2,
                        // strokeWidth: '0px',
                        // mb: 1,
                      }}
                    />
                  ),
              )}
            </Flex.container>
          )}

          {!!(authors || illustrators) && (
            <Box.container>
              {[
                { type: 'feather', content: authors },
                { type: 'image', content: illustrators },
                // { type: 'publishing_house', content: exhibitors },
              ].map(
                ({ type, content }) =>
                  content && (
                    // content[0] &&
                    // content[0].defaultName &&
                    <Flex {...{ key: type, alignItems: 'center' }}>
                      <FeatherIcon
                        {...{
                          icon: type,
                          color,
                          // fill: color,
                          size: 3,
                          mr: 1,
                          mb: 1,
                        }}
                      />
                      <Text
                        {...{
                          color: 'text',
                        }}
                      >
                        {/* {content && content.map(a => a.defaultName).join(', ')} */}
                        {content}
                      </Text>
                    </Flex>
                  ),
              )}
            </Box.container>
          )}

          {/* {learningAttribute && (
            <Box.container>
              <Text {...{ as: 'span', color: 'text', fontSize: 1 }}>
                {t('mainLearningAttribute')}:{' '}
              </Text>
              <Badge
                {...{
                  borderRadius: 3,
                  // mx: '1px',
                  // my: '1px',
                  // py: '2px',
                  // mr: 1,
                  bg: 'text',
                }}
              >
                {t(learningAttribute)}
              </Badge>
            </Box.container>
          )} */}

          <Description
            {...{
              description: pitch,
              color: mainColor,
              icon: 'zap',
              title: t('pitch'),
            }}
          />

          <Description
            {...{
              description,
              color: mainColor,
            }}
          />

          {videoId && (
            <Box.container
              {...{
                width: '100%',
                maxWidth: [300, 300, 600],
                // height: '100%',
                height: [300 / 1.777, 300 / 1.777, 600 / 1.777],
                // alignText: 'center',
                m: 'auto',
              }}
            >
              <Youtube
                {...{
                  videoId,
                  css: {
                    width: '100%',
                    height: '100%',
                  },
                }}
              />
            </Box.container>
          )}

          <SectionAnimations {...{ animations, t }} />
          <SectionExhibitors {...{ exhibitors, t }} />
          <SectionGuests {...{ guests: inventedBy, t, title: 'inventedBy' }} />
          <SectionGuests
            {...{ guests: illustratedBy, t, title: 'illustratedBy' }}
          />
        </React.Fragment>
      )}
    </Page>
  )
}

export const pageQuery = graphql`
  query Boardgame($id: String!) {
    boardgame(id: { eq: $id }) {
      id
      nameCanonical
      name {
        en
        fr
        nl
      }
      nameFallback {
        en
        fr
        nl
      }
      pitch {
        en
        fr
        nl
      }
      description {
        en
        fr
        nl
      }
      facebook
      featuredImage {
        url
        type
        thumbnails {
          full {
            height
            url
            width
          }
          large {
            height
            width
            url
          }
          small {
            height
            url
            width
          }
        }
      }
      duration
      accessibility
      age
      audience
      maxPlayers
      minPlayers
      pitch {
        en
        fr
        nl
      }
      relativePath
      website
      youtubeVideo {
        id
        url
        opts {
          startSeconds
        }
      }
      authors
      illustrators

      exhibitors {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        category
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }

      animations {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        days
        start
        end
        categories
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }

      inventedBy {
        nameCanonical
        name {
          fr
          en
          nl
        }
        nameFallback {
          fr
          en
          nl
        }
        status
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }

      illustratedBy {
        nameCanonical
        name {
          fr
          en
          nl
        }
        nameFallback {
          fr
          en
          nl
        }
        status
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }
    }
  }
`

export default BoardgameTemplate
